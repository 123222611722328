import React from 'react'
import * as style from "../static/styles/contact404pp.module.scss"
import Seo from "../components/seo"
import Hero from "../components/indexComponents/hero"
import Footer from "../components/footer"

const Contact = () => {
    return (
        <div className={style.contact404ppRoot}>
            <Seo title="Contact"/>
            <Hero/>
            <div className={style.insideWrapper}>
                <h1>Contact</h1>
                <form name="contact" method="POST" data-netlify="true" action="/success" data-netlify-honeypot="bot-field">
                    <input type="hidden" name="form-name" value="contact"/>
                    <div>
                        <label htmlFor="name">Name</label>  
                        <input type="text" name="name" id="name"　placeholder="" required/>
                        <label htmlFor="name">Company</label>  
                        <input type="text" name="name" id="companyName"　placeholder="" required/>
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email" placeholder="" required/>
                        <label htmlFor="textarea">Message</label>
                        <textarea name="message" rows="15" id="textarea" placeholder="" required></textarea>
                        <br/> 
                        <button type="submit" className={style.buttonChecked}>Send</button>
                    </div>
                </form> 
            </div>
            <Footer/>
        </div>
    )
}

export default Contact